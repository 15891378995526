@import "~@angular/material/prebuilt-themes/deeppurple-amber.css";
@import "./custom-theme";
// ? changed
@import "/src/app/common/styles/variables.scss";
/*@primary-color: #034ea2;*/
html, body {
    width: 100%;
    height: 100%;
    margin: 0;
    font-family: "Nunito Sans";
    font-weight: 600;
}

.mat-checkbox {
    .mat-checkbox-frame {
        border-color: $primary-color;
        border-width: 1px;
        height: 16px;
        width: 16px;
        margin: 0 8px 0 0;
        padding: 2px;
        border-radius: 2px;
    }

    .mat-checkbox-label {
        font-family: $font-family;
        font-size: $content-font-size;
        color: $text-color;
    }

    .mat-ripple-element {
        background-color: $primary-color !important;
    }
}

.mat-option-multiple {
    .mat-pseudo-checkbox {
        border-color: $primary-color;
        border-width: 1px;
        height: 16px;
        width: 16px;
        margin: 0 8px 0 0;
        padding: 2px;
        border-radius: 2px;
    }

    .mat-pseudo-checkbox-checked {
        background: $primary-color !important;

        &::after {
            top: 3.4px;
            left: 2px;
        }
    }
}

.mat-option {
    padding: 0px 12px;

    .mat-option-text {
        font-family: $font-family;
        font-size: $font-12;
        color: $text-color;
    }
}

.mat-checkbox-checked:not(.mat-checkbox-disabled).mat-accent
.mat-ripple-element {
    background: $primary-color !important;
}

.mat-checkbox-checked.mat-accent .mat-checkbox-background {
    background-color: $primary-color !important;
}

.mat-select {
    .mat-select-value-text {
        color: $text-color;
        font-size: $content-font-size !important;
        font-family: $font-family;
    }
}

.mat-select-panel-wrap {
    .mat-select-panel:not([class*="mat-elevation-z"]) {
        box-shadow: 0 0 10px 0 rgb(0 0 0 / 6%) !important;
        border-radius: 3px;

        .mat-option {
            padding: 0 12px;
        }
    }
}

.mat-form-field-appearance-outline {
    font-family: $font-family;
    margin-top: 3px;

    .mat-select-arrow-wrapper {
        transform: translateY(0) !important;
    }

    .mat-form-field-infix {
        padding: 8px 0px;
        border-top: 0px solid transparent;

        input.mat-input-element {
            font-family: $font-family;
            color: $text-color;
            font-size: $content-font-size;
            font-weight: 600;
        }
    }

    .mat-form-field-outline {
        color: $border-color;

        .mat-form-field-outline-start {
            border-radius: 3px 0 0 3px !important;
        }

        .mat-form-field-outline-end {
            border-radius: 0 3px 3px 0 !important;
        }
    }

    .mat-form-field-prefix {
        top: 8px !important;
        color: $primary-color;
    }

    .mat-form-field-label-wrapper {
        top: -5px;
        padding-top: 0px;

        .mat-form-field-label {
            color: $placeholder-color;
            font-size: $content-font-size !important;
        }
    }
}

.mat-form-field-appearance-outline.mat-focused {
    .mat-form-field-label {
        color: $primary-color;
    }

    .mat-form-field-ripple {
        background-color: $primary-color;
    }

    .mat-form-field-required-marker {
        color: $primary-color;
    }

    .mat-input-element {
        color: $primary-color;
    }

    .mat-form-field-outline {
        color: $primary-color !important;
    }
}

.mat-autocomplete-panel {
    border-radius: 3px !important;
    box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.08) !important;
    margin: 0px 0px;
}

.advanced-search-group {
    max-height: 400px !important;
    height: 353px !important;
    visibility: visible !important;
}

.advanced-synonym-group {
    height: 150px;
    overflow-y: auto;
    overflow-x: hidden !important;
    @include customScroller();
    visibility: visible !important;
    /* .mat-option:hover:not(.mat-option-disabled), .mat-option:focus:not(.mat-option-disabled) {
       background: $white-bg-color;
     }*/
}

.haq-application-filter {
    overflow-x: hidden !important;
    visibility: visible !important;
    margin: 5px 0px;
    padding: 8px 0px 8px 8px;
}


/** Global font changes for mat menu**/
.mat-menu-panel {
    width: 160px !important;

    .mat-menu-content {
        .mat-menu-item {
            font-weight: 600;
            font-stretch: normal;
            font-style: normal;
            line-height: 0.92;
            letter-spacing: normal;
            text-align: left;
            font-family: "Nunito Sans";
            font-size: 12.5px;
        }
    }
}

.basic-search-group {
    max-height: 206px !important;
    visibility: visible !important;
}

.mat-snack-bar-container {
    background: $white-color;
    box-shadow: 0 0 10px 0 rgb(0 0 0 / 8%);
    border-radius: 6px;

    &.snackbar-progress {
        background-color: #ffe8c6;
        padding: 9px 10px;
        min-height: auto;
        max-width: 450px;
        min-width: unset;
        margin: 10px !important;
        border: unset !important;
    }
}

.cdk-overlay-backdrop.cancel-export-backdrop, .cdk-overlay-backdrop.remove-history-backdrop, .cdk-overlay-backdrop.reset-haq-filter-backdrop {
    opacity: 0.8;
    background-color: $primary-color;
}

.following-cell {
    font-size: $content-font-size;
    font-family: $font-family;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.6;
    letter-spacing: normal;
    text-align: left;
    color: $placeholder-color;
    display: table;

    // margin: 0% 60% 0 0;
    padding: 0 6px;
    border-radius: 2px;
    background-color: $background-color-secondary;
}

.mat-spinner {
    circle {
        stroke: $primary-color !important;
    }
}

.add-border {
    border-left: 1px solid $tab-border-color;
    height: 450px;
    padding: 0px 50px;

}

.add-custom-scrollbar {
    height: 430px;
    overflow-y: auto;
    @include customScroller()
}

.active {
    background-color: $panel-bgColor;
    font-weight: bold !important;
}

.add-pagination-scrollbar {
    height: 320px;
    overflow-y: auto;
    @include customScroller();
}

.product-approval-content-area {
    @include bxShadow(1px 1px 6px 0, rgba(0, 0, 0, 0.1) !important);
    @include bxBorderRadius(6px !important);
    background-color: $white-color;
    height: 430px;
    padding: 26px;
    box-sizing: border-box;
    display: flex;
    justify-content: space-between;
    position: relative;
}

.width100 {
    width: 100%;
    display: block !important;
}

.select-all-space {
    margin-left: 28px;
}

.no-records-found {
    display: flex;
    justify-content: center;
    align-items: center;
    border-top: 1px solid $disabled_color;
    border-bottom: 1px solid $disabled_color;
    padding: 80px 0px 50px 0px;
}

.mat-form-field-appearance-outline .mat-form-field-outline-thick {
    @include addTextBoxHover($hover-color);

}

.mat-form-field-appearance-outline .mat-form-field-outline-thick .mat-form-field-outline-start, .mat-form-field-appearance-outline .mat-form-field-outline-thick .mat-form-field-outline-end, .mat-form-field-appearance-outline .mat-form-field-outline-thick .mat-form-field-outline-gap {
    border-width: 1px !important;
}

.product-meeting-no-results-page {
    position: relative;
    height: 320px;
}

.search-icon {
    width: 21px;
    height: 21px;
}

.query-details-modal-backdrop {
    background-color: rgba(3, 78, 162, 0.8);
}

.mat-checkbox-indeterminate.mat-accent .mat-checkbox-background, .mat-checkbox-checked.mat-accent .mat-checkbox-background {
    background-color: $primary-color;
}

.haq-pagination-dropdown {
    min-width: 43px !important;
    max-width: unset !important;
    width: auto !important;
    min-height: unset !important;

    .mat-menu-content {
        max-height: 110px;
        overflow-y: auto;
        overflow-x: hidden;
        @include customScroller();

        .mat-menu-item {
            height: 22px;
            padding: 0 10px;
            text-align: center;

            .menu-item-label {
                color: $placeholder-color;
                font-size: $content-font-size
            }
        }
    }
}

.compare-labels, .export-labels {
    .header-container {
        width: 100%;
        height: 105px;
        margin: 0 0 26px;
        padding: 0;
        background-color: #fff;

        header {
            padding-top: 25px;
            padding-left: 25px;
            padding-right: 25px;
        }

        .back-icon {
            cursor: pointer;
            color: $primary-color;
            padding-left: 20px;
        }

        .compare-label {
            font-family: $font-family;
            font-size: 19.5px;
            font-weight: 600;
            font-stretch: normal;
            font-style: normal;
            line-height: 0.92;
            letter-spacing: normal;
            text-align: left;
            color: #231f20;
            padding-left: 5px;
        }
    }
}
