$background-color: #f3f6fa;
$background-color-secondary: #ced6ff;
$white-bg-color: #ffffff;
$primary-color: #034ea2;
$border-color: #949494;
$text-color: #231f20;
$placeholder-color: #66676b;
$panel-bgColor: #e4eefc;
$content-font-size: 10px;
$white-color: #ffffff;
$font-exact12:12px;
$font-12: 12.5px;
$font-15: 15.5px;
$font-14: 14px;
$font-19: 19.5px;
$font-11: 11px;
$font-family: "Nunito Sans";
$font-weight-600: 600;
$font-stretch-normal: normal;
$font-style-normal: normal;
$font-style-italic: italic;
$letter-spacing-normal: normal;
$font-bold:bold;
$font-weight-normal: normal;
$disabled_color: #e6e7e8;
$border-color1: #1e4ea2;
$tab-border-color: #949494;
$arrow-bg-color: #597ebf;
$border-pink: #e30773;
$bg-color-k: #fdeaf3;
$hover-color: #2162D5;
$badge-color:#d3efea;
$black-color: #000;
$query-background: #eff4f7;
$font-weight-light: 300;
$font-weight-regular: 400;
$font-weight-semi-bold: 600;
$font-weight-bold: 700;

@mixin fontStyle_12 {
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.44;
  letter-spacing: normal;
  text-align: left;
  font-family: $font-family;
}

@mixin bxShadow($bxWidth, $bxColor) {
  -webkit-box-shadow: $bxWidth $bxColor;
  -moz-box-shadow: $bxWidth $bxColor;
  -ms-box-shadow: $bxWidth $bxColor;
  box-shadow: $bxWidth $bxColor;
}
@mixin bxBorderRadius($radius) {
  -webkit-border-radius: $radius;
  -moz-border-radius: $radius;
  -ms-border-radius: $radius;
  border-radius: $radius;
}
@mixin transitionEffect($transition...) {
  -moz-transition:    $transition;
  -o-transition:      $transition;
  -webkit-transition: $transition;
  transition:         $transition;
}
@mixin transformEffect($rotate) {
  -webkit-transform: rotate($rotate);
  -moz-transform: rotate($rotate);
  -ms-transform: rotate($rotate);
   transform: rotate($rotate);
}
@mixin translateEffect($x, $y) {
  -webkit-transform: translate($x, $y);
  -moz-transform:  translate($x, $y);
  -ms-transform: translate($x, $y);
   transform:  translate($x, $y);
}
@mixin cardWrapper {
  min-width: 422px;
  min-height: 240px;
  box-shadow: 0 0 10px 0 rgb(0 0 0 / 6%) !important;
  padding: 15px !important;
  border-radius: 6px !important;
}
@mixin boxOpacity($depth) {
  -webkit-opacity: $depth;
  -moz-opacity: $depth;
  -ms-opacity: $depth;
   opacity: $depth;
}
@mixin addHoverColor($bgColor, $textColor){
  background-color: $bgColor;
  color:$textColor;
  border-color: $bgColor;
}
@mixin addTextBoxHover($borderColor) {
  color:$borderColor;
}
@mixin customScroller {
  &::-webkit-scrollbar {
    width: 5px;
    height: 5px;
  }
  &::-webkit-scrollbar-track {
    box-shadow: inset 0 0 5px $disabled_color;
    border-radius: 10px;
  }
  &::-webkit-scrollbar-thumb {
    background: $border-color;
    border-radius: 10px;
  }
  &::-webkit-scrollbar-thumb:hover {
    background: $border-color;
  }
}
